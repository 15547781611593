import React from 'react';
import  Axios  from 'axios';
import { SETTING } from '../app-config/cofiguration';
import htmlParse from 'html-react-parser'
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Home extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
            allBlogs:[]
        }
    }
    componentDidMount(){
      
       this.getAllBlogPost();
    }
    getAllBlogPost=()=>{
         Axios.get(SETTING.APP_CONSTANT.API_URL+'public/getAllBlogPost')
        .then((res) =>  {
          
          const result= res.data.data
          console.log(result)
       
          this.setState({
            allBlogs:result
          })
        
        })
        .catch(function (error) {
          console.log(error);
        })
    }

    limitString =(string = '', limit = 0) =>{  
        console.log("daadad",`${string.substring(0, limit)}...</p>`)
        return `${string.substring(3, limit)}...`
    }
    render() {
      return <>
      <Navbar/>
        <div className="container mt-5">
            <div className="row">
                <div className="col-lg-9">
                    <Container>
                        <Row>
                            {this.state.allBlogs.map((blog, index)=> {return (      
                                  <Col key={index}>  
                                <Card style={{ width: '24rem' }}>
                                    <Card.Body>
                                    <Card.Title>{blog.title}</Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">Posted on {`${new Date(blog.created).toLocaleString('default', { month: 'long' })} ${new Date(blog.created).getDate()}, ${new Date(blog.created).getFullYear()}`} by {blog.author?blog.author:'Web Tech Pool'}</Card.Subtitle>
                                    <Card.Text>
                                        {this.limitString(blog.content,95)}
                                    </Card.Text>
                                    <Card.Link href={`blog/${blog.title}_${blog.postId}`}>Know more</Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>)})}
                        </Row>
                    </Container>
                </div>
                <div className="col-lg-3">
                    <div className="card mb-3">
                        <div className="card-header">Search</div>
                        <div className="card-body">
                            <div className="input-group">
                                <input className="form-control" type="text" placeholder="Enter search term..." aria-label="Enter search term..." aria-describedby="button-search" />
                                <button className="btn btn-primary" id="button-search" type="button">Go!</button>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-3">
                        <div className="card-header">Categories</div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-6">
                                    <ul className="list-unstyled mb-0">
                                        <li><a href="#!">Excel Trick</a></li>
                                        <li><a href="#!">Excel</a></li>
                                        <li><a href="#!">Web Design</a></li>
                                    </ul>
                                </div>
                                <div className="col-sm-6">
                                    <ul className="list-unstyled mb-0">
                                        <li><a href="#!">Youtube</a></li>
                                        <li><a href="#!">GIS</a></li>
                                        <li><a href="#!">JavaScript</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="card mb-4">
                        <div className="card-header">Side Widget</div>
                        <div className="card-body">You can put anything you want inside of these side widgets. They are easy to use, and feature the Bootstrap 5 card component!</div>
                    </div> */}
                </div>
            </div>
        </div>                                                              
        
        <Footer/>
      </>
    }
}
export default Home