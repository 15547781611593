import React,{useEffect, useState} from 'react';
import  Axios  from 'axios';
import { SETTING } from '../app-config/cofiguration';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import { useTable } from 'react-table'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const USER = localStorage.getItem("userInformation") && JSON.parse(localStorage.getItem("userInformation"));

const Styles = styled.div`
  padding: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid black;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
    }
  }
`
export default function MyBlogs () {

    const [blogList, setBlogList] = useState([])
 
    useEffect(() => {
        getAllBlogPost()
        },[])

    const getAllBlogPost=()=>{

        // let options = SETTING.HEADER_PARAMETERS;
        // options['Authorization'] = localStorage.getItem("token")
         Axios.get(SETTING.APP_CONSTANT.API_URL+'public/getAllBlogPost')
        .then((res) =>  {
          toast["success"]("Blog post data get successfully.");
          const result= res.data.data
          //console.log(result)
          setBlogList(result)
        
        })
        .catch(function (error) {
          toast["error"]("Blog post data not get successfully.");
          console.log(error);
        })
    }
    const deleteMyBlog=(id)=>{

        const sendToData={
            id:id
        }
        let options = SETTING.HEADER_PARAMETERS;
        options['Authorization'] = localStorage.getItem("token")
        Axios.post(SETTING.APP_CONSTANT.API_URL+'admin/deleteBlogPost',sendToData,{headers: options})
        .then((res) =>  {
          toast["success"]("Blog post data deleted successfully.");
            getAllBlogPost()
        })
        .catch(function (error) {
          toast["error"]("Blog post data not deleted successfully.");
          console.log(error);
        })
    }
    const data = blogList
    const columns = React.useMemo(
        () => [
          {
            Header: 'Created',
            accessor: 'created',
            Cell: ({ cell: { value } }) => new Date(value).toLocaleString()
          },
          {
            Header: 'Title',
            accessor: 'title',
          },
          {
            Header: 'Action',
            accessor: '_id',
            Cell: ({ cell: { value } }) => <button onClick={()=>deleteMyBlog(value)}>Delete</button>
          },
        ],
        []
      )

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
      })
    


      return (
        <>{USER && USER.userInfo.roleName==='ADMIN' ? <>
      <Styles>
        <table {...getTableProps()}>
        <thead>
            {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
            </tr>
            ))}
        </thead>
        <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
            prepareRow(row)
            return (
                <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
                </tr>
            )
            })}
        </tbody>
        </table>
      </Styles>
      </>
      :window.location.href='/'}
      </>
      )
    }
