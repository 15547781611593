import React from 'react';
import  Axios  from 'axios';
import { SETTING } from '../app-config/cofiguration';
import { Link } from 'react-router-dom';
class Navbar extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        }
    }
    // componentDidMount(){
    //    this.getBlogPost();
    // }
    // addPost=()=>{
    //     window.location.href='http://localhost:3000/addBlogs'
    // }
    //getBlogPost=()=>{
    //      Axios.get(SETTING.APP_CONSTANT.API_URL+'public/getBlogPost')
    //     .then((res) =>  {
          
    //       const result= res.data.data[0]
    //       console.log(result)
        
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     })
    // }
    render() {
      return <>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <div className="container">
                    <a className="navbar-brand" href="#!">WebTechPool</a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="#!">About</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="#!">Contact</Link></li>
                            <li className="nav-item"><Link className="nav-link" to="/login" target="_blank">Login</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
      </>
    }
}
export default Navbar