import React from 'react';
import  Axios  from 'axios';
import { SETTING } from '../app-config/cofiguration';
import { Link } from 'react-router-dom';
class Footer extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        }
    }
    // componentDidMount(){
    //    this.getBlogPost();
    // }
    // addPost=()=>{
    //     window.location.href='http://localhost:3000/addBlogs'
    // }
    //getBlogPost=()=>{
    //      Axios.get(SETTING.APP_CONSTANT.API_URL+'public/getBlogPost')
    //     .then((res) =>  {
          
    //       const result= res.data.data[0]
    //       console.log(result)
        
    //     })
    //     .catch(function (error) {
    //       console.log(error);
    //     })
    // }
    render() {
      return <>
           <footer className="py-5 bg-dark">
            <div className="container"><p className="m-0 text-center text-white">Copyright &copy; WebTechPool {new Date().getFullYear()}</p></div>
        </footer>
      </>
    }
}
export default Footer