
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import AddBlog from './pages/AddBlog';
import Home from './pages/Home';
import MyBlogs from './pages/MyBlogList'; 
import Blogs from './pages/Blogs'
import Login from './pages/Login';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" 
        // element={<Home />}
        >
          <Route index element={<Home />} />
          <Route path="/addBlogs" element={<AddBlog />} />
          <Route path="/login" element={<Login />} />
          <Route path="/myBlogList" element={<MyBlogs />} />
          <Route path="/blog/:title" element={<Blogs />} /> 
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );;
}

export default App;
