import React, {useState} from 'react';
import Axios from 'axios';
import { SETTING } from "../app-config/cofiguration";
import { Card} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Login(){
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

    const onSubmit= async(values)=>{
    const sendToData={
      bmmsId: values.username,
      password: (values.password),
      //password: encryptAES(values.password),
    
    }
    try {
      await Axios.post(SETTING.APP_CONSTANT.API_URL+'public/userlogin',sendToData)
      .then((res) =>{
        if (res && res.data.success) {
          const user = res.data.data.user
          localStorage.setItem("userInformation", JSON.stringify(res.data.data.user))
          localStorage.setItem("token", JSON.stringify(res.data.data.token))
          toast["success"]("Logged in successfully");
          const roleName= user.userInfo.roleName
          if(roleName==='ADMIN') window.location.href="/addBlogs"
        } else {
          toast["error"](res && res.data && res.data.message? res.data.message:"Email or Password is wrong Please try again");
        }
      })
      .catch(function (error) {
        toast["error"]("Email or Password is wrong Please try again");
        console.log(error);
      })
    
    } catch (error) {
      toast["error"]("Email or Password is wrong Please try again");
      // this.setState({
      //   loading2:false
      // })
      console.log(error);
    }
  
    }
 
      return  (
        <>
     <Card>
        <Card.Body>
        <Card.Title>Login</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">Welocome to WebTechPool.com</Card.Subtitle>
          <form onSubmit={handleSubmit((data)=> onSubmit(data))}>
          <div className="form-control">
          <label>Username</label>
            <input {...register('username',{ required: true })} />
            </div>
            <div className="form-control">
            <label>Password</label>
            <input {...register('password')} />
            </div>   
            <input type="submit" />
        </form>
        </Card.Body>
      </Card>
        </>
      )
}