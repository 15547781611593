import React, {useState} from 'react';
import Axios from 'axios';
import { SETTING } from "../app-config/cofiguration";
import { Button, Card} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import imageCompression from 'browser-image-compression';
import { ContentState, convertToRaw ,EditorState } from 'draft-js';
import { TagsInput } from "react-tag-input-component";
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const mime = require('mime')
//onst _contentState = ContentState.createFromText('Sample content state');
//const raw = convertToRaw(_contentState);  // RawDraftContentState JSON
const USER = localStorage.getItem("userInformation") && JSON.parse(localStorage.getItem("userInformation"));


export default function AddBlog(){
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

    const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
    )
    const [selectedImgData, setSelectedImgData] = useState({})
    const [selectedTag, setSelectedTag] = useState(["Excel"]);
   
    const onEditor=(data)=>{
        //let html = convertToHTML(editorState.getCurrentContent());
        //console.log("data", html)
        setEditorState(data)
    }

    const fileSelect = async (e) => {
      const selectedFile= e.target.files[0]
     if (selectedFile) {
          const postId=Math.floor(100000 + Math.random() * 900000)
          const doc = selectedFile
          const imgName= `excel_${postId}`
          const isValidExtension = doc ? ['png', 'jpg', 'jpeg'].includes(mime.getExtension(mime.getType(doc.name))) : false
          if(isValidExtension){
              const fileName = imgName+ '.' + mime.getExtension(mime.getType(doc && doc.name));
              const imgData = {
                imageFile: selectedFile,
                fileName: fileName,
                postId
                }
                setSelectedImgData(imgData)
          } else {
              toast.error(`Please select supported files "'png', 'jpg', 'jpeg'"`);
              document.getElementById(e.target.id).value = "";
          }
      }
      else {
          toast.error('Please select the document type to upload the file');
          document.getElementById("document").value = "";
      }
  }
  
    const onSubmit= async(values)=>{

      const selectedTagsName= selectedTag
      const uniqueSelectedTag = Array.from(new Set(selectedTagsName.map(data=>data.trim()).filter(value=>value!=='')));

      const imageFile = selectedImgData.imageFile
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
        fileType: 'image/jpeg',             // optional, fileType override e.g., 'image/jpeg', 'image/png' (default: file.type)
      }
    try {
      selectedImgData.fileName =selectedImgData.fileName.split('.')[0]+'.jpeg'
      //console.log("selectedDoc.fileName", selectedDoc.fileName)
      const compressedFile = await imageCompression(imageFile, options);
      const fd = new FormData();
      fd.append('avatar', compressedFile,selectedImgData.fileName);
      await Axios.post('https://bmmschool.in/uploadWebTechPool.php', fd,{
        headers: {
            'content-type': 'multipart/form-data'
        }}
      ).then(async(res)=> {
        if(res.data.status==='success'){

          let options = SETTING.HEADER_PARAMETERS;
          options['Authorization'] = localStorage.getItem("token")
         
      //console.log("editorStateeditorState",convertToHTML(editorState.getCurrentContent()))
       const sendToData={
          title:values.title,
          subTitle:values.subTitle,
          content: convertToHTML(editorState.getCurrentContent()),
          postImageUrl: selectedImgData.fileName,
          category:values.category,
          tags:uniqueSelectedTag,
          author:values.author,
          postId:selectedImgData.postId
        }
          await Axios.post(SETTING.APP_CONSTANT.API_URL+'admin/addBlogPost',sendToData,{headers: options})
          .then(function (response) {
            toast["success"]("Add new post successfully");
          
            clearFilter();
            console.log(response);
          })
          .catch(function (error) {
            toast["error"]("Error while posting new post.");
            console.log(error);
          })
        }}
      );
    } catch (error) {
      this.setState({
        loading2:false
      })
      console.log(error);
    }
  
    }
    const handleToShowMyBlogs=()=>{
      USER && USER.userInfo.roleName==='ADMIN' ? window.location.href='/myBlogList': alert('You are not authorised.')
    }
    const clearFilter=()=>{
      reset({})
      setEditorState(()=>EditorState.createEmpty())
      setSelectedImgData({})
      setSelectedTag([])
      document.getElementById("document").value = ""

    }
 
      return  (
        <>{USER && USER.userInfo.roleName==='ADMIN' ? <>
             <Card>
        <Card.Body>
        <Card.Title>Add New Post</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">WebTechPool.com</Card.Subtitle>
        <Button onClick={()=>handleToShowMyBlogs()}>Show my blogs</Button>
      <form onSubmit={handleSubmit((data)=> onSubmit(data))}>
      <div className="form-control">
      <label>Title</label>
        <input {...register('title',{ required: true })} />
        </div>
        <div className="form-control">
        <label>Sub Title</label>
        <input {...register('subTitle')} />
        </div>
        <div className="form-control">
        <TagsInput
          value={selectedTag}
          onChange={setSelectedTag}
          name="tags"
          placeHolder="Enter tags"
        /> 
        </div>
        <div className="form-control">
        <label>Category</label>
        <input {...register('category')} />
        </div> 
        <div className="form-control">
        <label>Author</label>
        <input {...register('author')} />
        </div> 
        <div className="form-control">
        <input type="file" id="document" name="document" onChange = {(e)=>fileSelect(e)}/>
        </div> 
        {/* {errors.lastName && <p>Last name is required.</p>}*/}
               <Editor
            editorState={editorState}
            onEditorStateChange={(data)=>onEditor(data)}
            wrapperClassName="wrapper-class-editor"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
        <input type="submit" />
    </form>
          
        </Card.Body>
      </Card>
        </>:window.location.href='/'}

        </>
      )

    
}